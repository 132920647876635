export const LIMIT_PRODUCT_STATUSES = {
	ACTIVE: 1,
	INACTIVE: 0,
};
export const LIMIT_PRODUCT_STATUS_OPTIONS = [
	{
		name: 'All statuses',
		value: null,
	},
	{
		name: 'Active',
		value: LIMIT_PRODUCT_STATUSES.ACTIVE,
	},
	{
		name: 'Inactive',
		value: LIMIT_PRODUCT_STATUSES.INACTIVE,
	},
];
export const LIMIT_PRODUCT_PERIODS_STATUSES = {
	ON_GOING: 'ongoing',
	SCHEDULED: 'scheduled',
	EXPIRED: 'expired',
};
export const LIMIT_PRODUCT_PERIODS_OPTIONS = [
	{ name: 'All statuses', value: null },
	{ name: 'Ongoing', value: LIMIT_PRODUCT_PERIODS_STATUSES.ON_GOING },
	{ name: 'Scheduled', value: LIMIT_PRODUCT_PERIODS_STATUSES.SCHEDULED },
	{ name: 'Ended', value: LIMIT_PRODUCT_PERIODS_STATUSES.EXPIRED },
];
export const LIMIT_PRODUCT_TABLE_FIELDS = [
	{ key: 'id', label: 'ID', class: 'col-id th-mw-50' },
	{ key: 'name', label: 'Limit product name', class: 'col-name th-mw-250' },
	{ key: 'isActive', label: 'Status', class: 'col-status th-mw-80' },
	{ key: 'quantity', label: 'Quantity', class: 'col-quantity th-mw-80' },
	{ key: 'startDate', label: 'Start date', class: 'col-date th-mw-150' },
	{ key: 'endDate', label: 'End date', class: 'col-date th-mw-150' },
	{ key: 'durationTime', label: 'Duration', class: 'col-duration th-mw-80' },
	{ key: 'period', label: 'Period', class: 'col-period th-mw-80' },
];
export const LIMIT_PRODUCT_TITLE = {
	STATUS: {
		[LIMIT_PRODUCT_STATUSES.ACTIVE]: 'Active',
		[LIMIT_PRODUCT_STATUSES.INACTIVE]: 'Inactive',
	},
	PERIODS_STATUSES: {
		[LIMIT_PRODUCT_PERIODS_STATUSES.ON_GOING]: 'Ongoing',
		[LIMIT_PRODUCT_PERIODS_STATUSES.SCHEDULED]: 'Scheduled',
		[LIMIT_PRODUCT_PERIODS_STATUSES.EXPIRED]: 'Ended',
	},
};
export const LIMIT_PRODUCT_COLOR = {
	STATUS: {
		[LIMIT_PRODUCT_STATUSES.ACTIVE]: 'success',
		[LIMIT_PRODUCT_STATUSES.INACTIVE]: 'alto',
	},
	PERIODS_STATUSES: {
		[LIMIT_PRODUCT_PERIODS_STATUSES.ON_GOING]: 'hypertext',
		[LIMIT_PRODUCT_PERIODS_STATUSES.SCHEDULED]: 'info',
		[LIMIT_PRODUCT_PERIODS_STATUSES.EXPIRED]: 'alto',
	},
};
